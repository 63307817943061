<template>
<div>
  <emulators-banner />  
  <div class="text-center" v-if="!loggedIn">
    <h1>Admin</h1>
    <img src="../../assets/google-sign-in.png" @click="signIn" width="300px"/>
    <p v-if="loginError">
      {{ loginError }}
    </p>
  </div>
  <div class="d-flex flex-row" v-else>
    <!-- side panel -->
    <div class="p-2 sidepanel">
      <h2 class="m-2 mb-4">Admin</h2>
      <b-nav pills vertical>
        <b-nav-item to="/admin/users" exact active-class="active">Users</b-nav-item>
        <b-nav-item to="/admin/events" exact active-class="active">Events</b-nav-item>
        <div class="ml-3" v-if="manager">
          <b-nav-item v-for="e in manager.activeEvents" v-bind:key="e" :to="'/admin/event/'+e" exact active-class="active">{{e}}</b-nav-item>  
        </div>
        <b-nav-item to="/admin/studio" exact active-class="active">Studio</b-nav-item>
        <div v-if="$debug.isSuperAdmin" class="alert-info rounded">
          <b-nav-item to="/admin/accounts" exact active-class="active">Accounts</b-nav-item>
          <b-nav-item to="/admin/ops" exact active-class="active">Operations</b-nav-item>
          <b-nav-item to="/admin/logs" exact active-class="active">Logs</b-nav-item>
        </div>
      </b-nav>
    </div>
    <div class="mt-3 workarea">
      <router-view :manager="manager" v-if="manager"></router-view>
      <div v-else>Loading manager...</div>
    </div>
  </div>
  <div class="fixed-bottom">
    <div class="small text-light bg-dark">
      &nbsp;{{ $app.productNameLC }}@{{ appVersion }}
      <div class="d-inline" v-if="$debug.isSuperAdmin">
        - superadmin <input type="checkbox" v-model="$debug.isSuperAdminOn"/>
        - developer <input type="checkbox" v-model="$debug.isDeveloperOn"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { firebase } from "@/services/firebase";
import { useEmulators } from "@/services/firebase";
import { db } from '@/services/db';
import { getLog } from "@/services/log";
let log = getLog('admin-router');

import "firebase/compat/auth";
let auth = firebase.auth();
let googleAuthProvider = () => new firebase.auth.GoogleAuthProvider();

export default {
  components: {
    "emulators-banner": () => import("@/components/emulatorsBanner.vue"),
  },
  data() {
    return {
      useEmulators,
      manager: null,
      loggedIn: false,
      loginError: null,
      account: null,

      appVersion: require("@/../package.json").version,
    }
  },
  mounted() {
    document.title = "Admin - " + this.$app.productName;
    this.initAuth();
    this.$bind("manager", db.collection("LiveEvents").doc("manager"));
  },
  methods: {
    initAuth() {
      auth.onAuthStateChanged(user => {
        this.account = user;
        log.log("onAuthStateChanged user=", user);
        if (!user)
          this.loginError = "User not authenticated";
        else if (user.email == "laurent.mascherpa@gmail.com") {
          this.$debug.isSuperAdmin = true;
          this.loggedIn = true;
        }
        else
          this.loginError = `User ${user.email} is not authorized`;
      });
    },
    signIn() {
      var provider = googleAuthProvider();
      auth.signInWithPopup(provider).then(result => {
        log.log("signIn=", result);
        this.account = result.user;
      }).catch(error => {
        this.loginError = error;
        log.log("signInWithPopup error,", error);
      });
    },
  }
}
</script>

<style scoped>

.sidepanel {
  flex: 0 0 180px;
}

.workarea {
  width: calc(100vw - 200px);
}

</style>